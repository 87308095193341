<template>
  <div class="drug">
    <div class="page_row">
      <div class="list">
        <div class="drug_item" @click="toDrueDetails(item)" v-for="item in list" :key="item.id">
          <img :src="`https://img.infox-med.com/webImg/infoPc/drug/${item.id}.png`" alt="">
          <div class="drug_name">{{item.name}}</div>
        </div>
      </div>
    </div>
    <MyDialog :isShow="showDialog" @handleClose="handleClose" title="系统提示" width="500px"
              height="400px">
      <div class="dialog_container">
        <p>内容仅限【高级会员】可查看</p>
        <p class="warn">该药品信息为收费信息,查看详情需登陆付费后才可观看!会员15元/月!</p>
        <p>请添加客服微信咨询</p>
        <img src="https://img.infox-med.com/webImg/public/wechatCode.png" alt="">
        <p class="code_warn">扫码添加客服微信</p>
      </div>
    </MyDialog>
  </div>
</template>

<script>
import MyDialog from '@c/myDialog/newIndex.vue'
export default {
  props: {},
  components: {
    MyDialog
  },
  data () {
    return {
      list: [
        {
          id: 1,
          name: '速效救心丸'
        }, {
          id: 2,
          name: '小儿清咽颗粒'
        }, {
          id: 3,
          name: '抗病毒口服液'
        }, {
          id: 4,
          name: '三九胃泰颗粒'
        }, {
          id: 5,
          name: '盐酸氟桂利嗪胶囊'
        }, {
          id: 6,
          name: '藿香正气丸'
        }, {
          id: 7,
          name: '布各芬缓释放囊'
        }, {
          id: 8,
          name: '苯磺酸氨氯地平片'
        }, {
          id: 9,
          name: '脑心通胶囊'
        }, {
          id: 10,
          name: '麻杏止暾糖浆'
        }, {
          id: 11,
          name: '小儿咳暾宁糖浆'
        }, {
          id: 12,
          name: '地奥心血康软胶囊'
        }
      ],
      showDialog: false
    }
  },
  methods: {
    handleClose () {
      this.showDialog = false
    },
    toDrueDetails (item) {
      if (!this.$store.getters.userId) return this.$login({ loginWarning: '该药品信息为收费信息,查看详情需登陆付费后才可观看!会员15元/月!' })
      this.showDialog = true
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.drug {
  background-color: #f5f7fb;
}
.page_row {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
}
.list {
  display: grid;
  grid-template-columns: repeat(4, 24%);
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;

  min-height: calc(100vh - 255px);
  .drug_item {
    position: relative;
    height: 288px;
    height: 288px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
    img {
      width: 100%;
      transform: scale(1);
      transition: all 0.3s;
    }
    .drug_name {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      font-size: 16px;
      padding: 10px 20px;
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: 0 0 6px 6px;
    }
  }
}
.dialog_container {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 20px;
  font-size: 16px;
  p {
    margin-bottom: 5px;
  }
  .warn {
    color: #fa6064;
    font-size: 14px;
  }
  img {
    width: 200px;
  }
  .code_warn {
    padding-left: 4px;
    margin-top: 10px;
    letter-spacing: 8px;
  }
}
</style>
